import { useCallback, useEffect, useMemo } from 'react';
import { tryCatch, always } from 'ramda';
import { useSessionQueryParams } from './useSessionQueryParams';

const CJ_CART_STORE_KEY = 'cjCart';

interface CJCartItem {
  skuId: number;
  timestamp: number;
}

export const getStoredCart = (): CJCartItem[] => {
  if (typeof window === 'undefined') return [];
  const stored = localStorage.getItem(CJ_CART_STORE_KEY);
  return tryCatch(JSON.parse, always([]))(stored ?? '[]');
};

export const useCJCart = () => {
  const { params: sessionParams } = useSessionQueryParams();

  const isInCJWindow = useMemo(() => {
    return Boolean(
      sessionParams.cjevent || sessionParams.AID || sessionParams.PID || sessionParams.SID
    );
  }, [sessionParams]);

  const getCJCart = useCallback((): CJCartItem[] => {
    return getStoredCart();
  }, []);

  const addToCJCart = useCallback(
    (skuId: number) => {
      if (!isInCJWindow) return false;

      const currentCart = getCJCart();
      const now = Date.now();

      if (!currentCart.some(item => item.skuId === skuId)) {
        localStorage.setItem(
          CJ_CART_STORE_KEY,
          JSON.stringify([...currentCart, { skuId, timestamp: now }])
        );
        return true;
      }
      return false;
    },
    [getCJCart, isInCJWindow]
  );

  const removeFromCJCart = useCallback(
    (skuId: number) => {
      const currentCart = getCJCart();
      const updatedCart = currentCart.filter(item => item.skuId !== skuId);

      if (updatedCart.length !== currentCart.length) {
        localStorage.setItem(CJ_CART_STORE_KEY, JSON.stringify(updatedCart));
        return true;
      }
      return false;
    },
    [getCJCart]
  );

  const resetCJCart = useCallback(() => {
    localStorage.removeItem(CJ_CART_STORE_KEY);
  }, []);

  useEffect(() => {
    if (!isInCJWindow) resetCJCart();
  }, [isInCJWindow, resetCJCart]);

  return {
    addToCJCart,
    removeFromCJCart,
    resetCJCart,
    getCJCart,
    isInCJWindow
  };
};
