import { gql } from '@apollo/client';
import React from 'react';
import { Breakpoint } from '@moda/om';
import { MobileNav } from '../MobileNav';
import { DesktopNavigationProvider } from '../DesktopNavigation/DesktopNavigationProvider';
import { DesktopSiteNav, DESKTOP_SITE_NAV_FRAGMENT } from './DesktopSiteNav';

import './SiteNav.scss';

export const SITE_NAV_FRAGMENT = gql`
  fragment SiteNavFragment on DisplayPage {
    ...DesktopSiteNavFragment
  }
  ${DESKTOP_SITE_NAV_FRAGMENT}
`;

export const SiteNav: React.FC = () => {
  return (
    <div className="SiteNav">
      <Breakpoint gt="md">
        <DesktopNavigationProvider>
          <DesktopSiteNav />
        </DesktopNavigationProvider>
      </Breakpoint>

      <Breakpoint lt="md">
        <MobileNav />
      </Breakpoint>
    </div>
  );
};
