import { Clickable, Stack, Text } from '@moda/om';
import React from 'react';
import { MobileNavigatorVerticalFeaturedItemFragment } from '../../../generated/types';

import './MobileNavEditorialsSection.scss';

interface Props {
  featuredItems: MobileNavigatorVerticalFeaturedItemFragment[];
}

const sanitizeUrl = (url: string | null) => {
  if (!url) return undefined;

  try {
    // we can log this -- here the url looks like https://modaoperandi.com/clothing/etc and it should be strict pathname
    return new URL(url).pathname;
  } catch {
    return url;
  }
};

export const MobileNavEditorialsSection: React.FC<Props> = ({ featuredItems: editorials }) => (
  <Stack className="MobileNavEditorialsSection" direction="horizontal" space={3}>
    {editorials.map(({ anchorText, imageSrc, imageAlt, href: url, headline }, index) => (
      <Stack key={index} space={2}>
        {headline && <Text>{headline}</Text>}

        {!headline && <div className="MobileNavEditorialsSection__placeholder" />}

        <Clickable href={sanitizeUrl(url)}>
          <img
            alt={imageAlt || 'Editorial Image'}
            className="MobileNavEditorialsSection__image"
            key={`${imageSrc} -- ${imageAlt} -- ${index}`}
            src={imageSrc || ''}
          />
        </Clickable>

        {anchorText && <Text>{anchorText}</Text>}
      </Stack>
    ))}
  </Stack>
);
