import { isClientSide } from '@moda/portal-stanchions';
import { pick, reject, isNil } from 'ramda';

const compactObject = reject(isNil);

// NOTE: DO NOT INCLUDE SECRETS + DO NOT SPECIFY SECRETS AS ENV VARIABLES
// Only include what can be visible in client-side code
export const DEFAULTS = {
  ADYEN_CSE_PUBLIC_KEY:
    '10001|B8EFC628A7D54CEA83A8F7CEC87AF4F1A1DE57BB1948E79B4F1DABD988E52252AEA2DFA39D9FD9E79C6B5877AFCB45CB173CEB2CC75E5C46CB25DC9E0D5D96A540062A17E4BA23532319289EEC7EEE202C976F136B55EE1E85C90C7ADB817DE0672820D52089E4D9347D6588E259539940DAB2290F0358E2C88E9185B470BC8B1E83B9F89F70E85F0B1E777FDF639978E22865E4A948FF6B640640FDB7C71903B515E912A81930CCF0C5EFA70FD90A49E0A2962B1B6A6FCB84D07705A9B679B66A5B21C22C9E94981DFE5FD7DA90732317D400166D62179F4A98A9540518F0E100EEDDB05FBA29C79E42FFA2A70641E70E615E7E4D24AD83687BC1E07285493D',
  ADYEN_SCRIPT_LOCATION: 'https://test.adyen.com/hpp/cse/js/7814611594890689.shtml',
  AFFIRM_PUBLIC_API_KEY: 'D3EEDKK2SIVBRT9I',
  AFFIRM_SCRIPT_URL: 'https://cdn1-sandbox.affirm.com/js/v2/affirm.js',
  ALGOLIA_APPLICATION_ID: 'Z8CHXVEJ2D',
  ALGOLIA_API_KEY: '51e8050565d2891eb06cf113aefd3f8d',
  ALGOLIA_SUFFIX: 'production',
  AMAZON_CLIENT_ID: 'amzn1.application-oa2-client.39eeff70f0a54ef1be2091d8417cf6b8',
  AMAZON_SELLER_ID: 'A3A0774067UYF7',
  AMAZON_WIDGET_SCRIPT_LOCATION:
    'https://static-na.payments-amazon.com/OffAmazonPayments/us/sandbox/js/Widgets.js?sellerId=A3A0774067UYF7',
  APPLE_APP_ID: 'V3V3Y56QYK.com.modaoperandi.app',
  APPLE_CERTIFICATE_BUCKET_NAME: 'modaoperandi-ecommerce-apple-certs-prod',
  APPLE_MERCHANT_IDENTIFIER: 'merchant.com.sandbox.modaoperandi',
  BRIGHTCOVE_ACCOUNT_ID: '6415497666001',
  BRIGHTCOVE_API_BASE_URL: 'https://edge.api.brightcove.com/playback/v1/accounts',
  BRIGHTCOVE_POLICY_KEY:
    'BCpkADawqM3g5ua_JrPJOb6Xn9-8nNmXLeJDptc-TVwL7n2-8q0FZgc4laVlPD4rWUIEzxaWEfKFrUWMWlNyrwdc846bfqFb2d3KpBVQVA7cvn9v9F-bwMwyXCb_ScrC92JBC6amvody977X',
  DEV_TOOLS: 'false',
  BYPASS_RACK_ATTACK: '',
  DISCOVERY_API_URL: 'https://www.modaoperandi.com',
  ENABLE_IMG_RESIZING: 'true',
  ENVIRONMENT: '',
  FACEBOOK_APP_ID: '1785355605120041',
  GA4_MEASUREMENT_ID: '',
  GA_ID: 'UA-18917122-20',
  GOOGLE_KEY: 'AIzaSyCmyjup8bffkTwWgZu_KcvfcdrUsHtfI_s',
  IMAGE_SERVER_ROOT: 'http://image-server.image-server.svc.cluster.local',
  IMAGE_TAG: 'dev',
  LD_SDK_KEY: 'sdk-82ff2820-c71c-40f2-b0b6-158855f87c6d',
  LOG_LEVEL: 'info',
  MODA_API_URL: 'https://api.modaoperandi.com/public',
  MODA_IMG_ROOT: 'https://cdn.modaoperandi.com',
  MODA_IMG_SERVER_ROOT: 'https://images.modaoperandi.com',
  NEW_RELIC_APP_NAME: '',
  NODE_ENV: 'production',
  PERSISTED_QUERIES_MAX_VARIABLES_COUNT: '100',
  PREFERENCES_API_URL: 'https://preferences-api.prod.api.modaapps.com',
  RECAPTCHA_V2_KEY: '6LfsDBIkAAAAANiLzbiCgLbNiXulvrl1lAL0fNKl',
  RECAPTCHA_V3_KEY: '6LeWXj0mAAAAAO8XXnFiZeU6qg8sxgyhNVm2zsLD',
  RECOMMENDATIONS_API_URL: 'https://recommendations-api-v2.prod.api.modaapps.com',
  SEARCH_API_GRAPHQL_ENDPOINT: 'https://search.modaoperandi.com/graphql',
  SEGMENT_API_HOST: 'https://api.segment.io',
  SEGMENT_CDN_HOST: 'https://cdn.segment.com',
  SEGMENT_WRITE_KEY: 'ELcwnc9Ytx0TSHBDURTV4MIYeAfn46XY',
  SMARTYSTREETS_ID: '8fb46b2b-3e73-4942-2d1c-f5ce26c3f4a1',
  SMARTYSTREETS_KEY: '19402208121150742',
  SMARTYSTREETS_TOKEN: 'CptlJd3nXbcxOzXXI3oS',
  SMARTYSTREETS_URL: 'https://us-zipcode.api.smartystreets.com',
  TAG: '',
  USER_API_URL: 'https://user-api.prod.api.modaapps.moda',
  ZENDESK_API_TOKEN: '1rvBrOcuf69w10ElqobRjEsKluCsehVE6ChtNGg9'
};

const keys = Object.keys(DEFAULTS) as Key[];
const filtered = pick(keys);

export type Env = typeof DEFAULTS;
export type Key = keyof Env;

// NOTE: Precedence is:
// env vars from server > env vars from compiler > defaults
export const env = {
  environment: (): Env => {
    return {
      ...DEFAULTS,
      ...compactObject(
        isClientSide()
          ? {
              ...keys.reduce((acc, key) => ({ ...acc, [key]: (process.env || {})[key] }), {}),
              ...(window.__ENV__ || {})
            }
          : filtered(process.env as Env)
      )
    };
  },
  get: (key: Key, defaultValue?: string): string => env.environment()[key] || `${defaultValue}`
};
