import { useMemo } from 'react';
import { filter } from 'ramda';
import {
  NavigatorPlatform,
  NavigatorVertical,
  MobileNavigatorVerticalsQuery,
  MobileNavigatorVerticalsQueryVariables,
  MobileNavigatorVerticalFragment
} from '../../generated/types';
import { useSkippableQuery } from '../useSkippableQuery';
import { MOBILE_NAVIGATOR_VERTICALS_QUERY } from './mobileNavigatorQuery.gql';

const isMobileNavigator = (
  navigator: Pick<NavigatorVertical, '__typename'>
): navigator is MobileNavigatorVerticalFragment =>
  navigator.__typename === 'MobileNavigatorVertical';

const useMobileNavigatorQuery = () => {
  const { data, loading, error } = useSkippableQuery<
    MobileNavigatorVerticalsQuery,
    MobileNavigatorVerticalsQueryVariables
  >(MOBILE_NAVIGATOR_VERTICALS_QUERY, {
    variables: {
      platform: NavigatorPlatform.MOBILE
    }
  });

  const verticals = useMemo(() => {
    if (!data) return;

    return filter(isMobileNavigator, data.navigatorVerticals);
  }, [data]);

  return {
    data: verticals,
    loading,
    error
  };
};

export const useMobileNavigator = () => {
  const { data, loading, error } = useMobileNavigatorQuery();

  return { data, loading, error };
};
