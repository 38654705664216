import { Stack } from '@moda/om';
import React, { useCallback, useState } from 'react';
import FavoriteOutlineIcon from '@moda/icons/favorite-outline-24';
import { useQuery } from '@apollo/client';
import { useUser } from '../../../hooks/useUser';
import { hrefFor } from '../../../routers';
import { useCountry } from '../../CountrySelector';
import { MobileSiteNavOption } from '../../SiteNav/MobileSiteNavOption';
import { MobileNavHeader } from '../MobileNavHeader';
import { MobileNavTab } from '../MobileNavTab';
import { MobileSiteNavCountrySelector } from '../../SiteNav/MobileSiteNavCountrySelector';
import { useShoppableEditsFlag } from '../../../hooks/useShoppableEditsFlag';
import { GET_SHOPPABLE_EDITS_QUERY } from '../../ShoppableEdits/shoppableEdits.rest';

import './MobileNavContent.scss';

enum Pane {
  Default,
  CurrencyChange
}

interface Props {
  onClose: () => void;
}

export const MobileNavContent: React.FC<Props> = ({ onClose }) => {
  const [selectedPane, setSelectedPane] = useState(Pane.Default);
  const { user } = useUser();
  const {
    country: { alpha2Code: currency }
  } = useCountry();

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const handleChangeTab = useCallback((position: number) => setSelectedTabIndex(position), []);

  const showEditsInCustomerAccount = useShoppableEditsFlag();
  const { data: editsData } = useQuery(GET_SHOPPABLE_EDITS_QUERY, {
    skip: !showEditsInCustomerAccount
  });

  const shoppableEdits = Array.isArray(editsData?.shoppableEdits)
    ? editsData.shoppableEdits.filter(edit => edit && edit.id !== null && edit.shared == true)
    : [];

  return (
    <Stack className="MobileNavContent" space={0}>
      {selectedPane === Pane.Default && (
        <>
          <MobileNavHeader selectedTabIndex={selectedTabIndex} onChange={handleChangeTab} />

          <div className="MobileNavContent__tab-content">
            <MobileNavTab selectedTabIndex={selectedTabIndex} onClose={onClose} />
          </div>

          <div className="MobileNavContent__user-actions">
            {!user.isLoggedIn ? (
              <MobileSiteNavOption
                onClick={onClose}
                href={hrefFor.RegisterPage({ redirectTo: location.pathname })}
              >
                Sign In/Register
              </MobileSiteNavOption>
            ) : (
              <MobileSiteNavOption onClick={onClose} href={hrefFor.AccountSettings()}>
                My Account
              </MobileSiteNavOption>
            )}

            <MobileSiteNavOption onClick={() => setSelectedPane(Pane.CurrencyChange)}>
              My Currency ({currency})
            </MobileSiteNavOption>

            <MobileSiteNavOption onClick={onClose} href={hrefFor.FavoritesPage()}>
              My Favorites <FavoriteOutlineIcon />
            </MobileSiteNavOption>

            {showEditsInCustomerAccount && editsData && shoppableEdits.length > 0 && (
              <MobileSiteNavOption onClick={onClose} href={hrefFor.EditsPage()}>
                Looks for You
              </MobileSiteNavOption>
            )}

            {user.isLoggedIn && (
              <MobileSiteNavOption onClick={onClose} href={hrefFor.LogoutPage()}>
                Sign Out
              </MobileSiteNavOption>
            )}
          </div>
        </>
      )}

      {selectedPane === Pane.CurrencyChange && (
        <MobileSiteNavCountrySelector onClose={() => setSelectedPane(Pane.Default)} />
      )}
    </Stack>
  );
};
