import React, { useState, useEffect, useCallback } from 'react';
import classNames from 'classnames';
import { Modal } from '@moda/om';
import { MobileNavMenu } from '../MobileNavMenu';
import { Loading } from '../../Loading';
import { useMobileNavigator } from '../../../hooks/useNavigator';

import './MobileNavOverlay.scss';

enum Mode {
  Resting,
  Mounted
}

interface Props {
  onClose(): void;
  navRef: HTMLDivElement | null;
  isSearchOpen: boolean;
}

export const MobileNavOverlay: React.FC<Props> = ({ onClose, navRef, isSearchOpen }) => {
  const [top, setTop] = useState<undefined | number>(undefined);
  const [mode, setMode] = useState(Mode.Resting);
  const { loading } = useMobileNavigator();

  const calculateTop = useCallback(() => {
    const siteNav = document.querySelector('.SiteNav');

    if (siteNav) {
      const { top, height } = siteNav.getBoundingClientRect();

      return top + height;
    }
  }, []);

  useEffect(() => setMode(Mode.Mounted), []);

  useEffect(() => setTop(calculateTop), [calculateTop]);

  useEffect(() => setTop(calculateTop), [calculateTop, isSearchOpen]);

  const handlePreventClose = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => event.stopPropagation(),
    []
  );

  return (
    <Modal
      className={classNames('MobileNavOverlay', {
        'MobileNavOverlay--active': mode === Mode.Mounted
      })}
      tabIndex={0}
      role="button"
      onClose={onClose}
      style={{ top }}
      shards={navRef ? [navRef] : undefined}
    >
      {loading && <Loading />}

      <MobileNavMenu onClick={handlePreventClose} onClose={onClose} />
    </Modal>
  );
};
