import { gql } from '@apollo/client';

const MOBILE_NAVIGATOR_UNIT_LIST_ITEM_FRAGMENT = gql`
  fragment MobileNavigatorUnitListItemFragment on NavigatorUnitListItem {
    href
    id
    title
    type
  }
`;

const MOBILE_NAVIGATOR_UNIT_FRAGMENT = gql`
  fragment MobileNavigatorUnitFragment on ListNavigatorUnit {
    header
    id
    list {
      ...MobileNavigatorUnitListItemFragment
    }
  }
  ${MOBILE_NAVIGATOR_UNIT_LIST_ITEM_FRAGMENT}
`;

const MOBILE_NAVIGATOR_VERTICAL_ITEM_FRAGMENT = gql`
  fragment MobileNavigatorVerticalItemFragment on MobileNavigatorItem {
    id
    href
    name
    style {
      bold
      color
    }
    units {
      ...MobileNavigatorUnitFragment
    }
  }
  ${MOBILE_NAVIGATOR_UNIT_FRAGMENT}
`;

const MOBILE_NAVIGATOR_VERTICAL_FEATURED_ITEM_FRAGMENT = gql`
  fragment MobileNavigatorVerticalFeaturedItemFragment on MobileNavigatorVerticalFeaturedItem {
    anchorText
    headline
    href
    imageAlt
    imageSrc
  }
`;

const MOBILE_NAVIGATOR_VERTICAL_FRAGMENT = gql`
  fragment MobileNavigatorVerticalFragment on MobileNavigatorVertical {
    id
    href
    items {
      ...MobileNavigatorVerticalItemFragment
    }
    featured {
      ...MobileNavigatorVerticalFeaturedItemFragment
    }
    title
  }
  ${MOBILE_NAVIGATOR_VERTICAL_ITEM_FRAGMENT}
  ${MOBILE_NAVIGATOR_VERTICAL_FEATURED_ITEM_FRAGMENT}
`;

export const MOBILE_NAVIGATOR_VERTICALS_QUERY = gql`
  query MobileNavigatorVerticalsQuery($platform: NavigatorPlatform!) {
    navigatorVerticals: navigator_verticals(platform: $platform) {
      ... on MobileNavigatorVertical {
        ...MobileNavigatorVerticalFragment
      }
    }
  }
  ${MOBILE_NAVIGATOR_VERTICAL_FRAGMENT}
`;
