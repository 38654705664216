import { Clickable, ControlLink, Divider, Stack, Text, TextColor } from '@moda/om';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ExpandIcon from '@moda/icons/expand-12';
import ChevronRightIcon from '@moda/icons/chevron-right-12';
import HideIcon from '@moda/icons/hide-12';
import { titleize } from '@moda/portal-stanchions';
import { MobileNavSubcategoriesSection } from '../MobileNavSubcategoriesSection';
import { useMobileNavigator } from '../../../hooks/useNavigator';
import { MobileNavEditorialsSection } from '../MobileNavEditorialsSection';

import './MobileNavTab.scss';

interface Props {
  selectedTabIndex: number;
  onClose: () => void;
}

export const MobileNavTab: React.FC<Props> = ({ selectedTabIndex, onClose }) => {
  const [expandedCategory, setExpandedCategory] = useState<number>();
  const { data: verticals } = useMobileNavigator();
  const verticalContent = verticals?.[selectedTabIndex];

  const categories = useMemo(() => {
    if (!verticalContent) return [];
    return verticalContent.items;
  }, [verticalContent]);

  const handleExpand = useCallback(
    (index: number) => {
      if (expandedCategory === index) {
        setExpandedCategory(undefined);
        return;
      }

      setExpandedCategory(index);
    },
    [expandedCategory]
  );

  useEffect(() => setExpandedCategory(undefined), [selectedTabIndex]);

  if (!verticalContent) return null;

  return (
    <Stack className="MobileNavTab" space={4}>
      <Stack
        className="MobileNavTab__header-wrapper"
        direction="horizontal"
        justifyContent="space-between"
        space={1}
      >
        <ControlLink
          className="MobileNavTab__header"
          href={`/${verticalContent.href}`}
          underlined={false}
        >
          {verticalContent.title.toUpperCase() === 'HOME'
            ? 'Home'
            : `${titleize(verticalContent.title)} Home`}
        </ControlLink>

        <div className="MobileNavTab__chevron-right-icon">
          <ChevronRightIcon />
        </div>
      </Stack>

      <Divider />

      {categories.map((category, index) => {
        if (category.href !== '' && category.units.length === 0) {
          return (
            <Clickable
              className="MobileNavTab__category"
              key={`${category.name} -- ${index}`}
              href={category.href}
            >
              <Text
                color={category.style.color as TextColor}
                treatment={category.style.bold ? 'bold1' : 'body1'}
              >
                {category.name}
              </Text>
            </Clickable>
          );
        }

        return (
          <React.Fragment key={`${category.name} -- ${index}`}>
            <Clickable className="MobileNavTab__category" onClick={() => handleExpand(index)}>
              <Stack
                className="MobileNavTab__category-name"
                direction="horizontal"
                justifyContent="space-between"
                space={1}
                alignItems="center"
              >
                <Text
                  color={category.style.color as TextColor}
                  treatment={category.style.bold ? 'bold1' : 'body1'}
                >
                  {category.name}
                </Text>
                <div className="MobileNavTab__plus-minus-icon">
                  {expandedCategory !== index ? <ExpandIcon /> : <HideIcon />}
                </div>
              </Stack>
            </Clickable>
            {expandedCategory === index &&
              category.units.map((unit, unitIndex) => (
                <MobileNavSubcategoriesSection
                  key={`${unit.header} -- ${unitIndex}`}
                  unit={unit}
                  onClose={onClose}
                />
              ))}
          </React.Fragment>
        );
      })}

      {verticalContent.featured.length > 0 && (
        <>
          <Divider />
          <MobileNavEditorialsSection featuredItems={verticalContent.featured} />
        </>
      )}

      <Divider />
    </Stack>
  );
};
