import { gql, TypedDocumentNode } from '@apollo/client';

export type ShoppableEdit = {
  id: number;
  name: string;
  items: [
    {
      color: string;
      id: number;
      productId: number;
      quantity: number;
      size: string;
      skuId: number;
      status: string;
      trunkshowId: number;
      userId: number;
      variantId: number;
    }
  ];
  userId: number;
  shared: boolean;
  status: string;
  stylistName: string;
};

export type GetShoppableEditsVariables = {
  status: string;
};

export const GET_SHOPPABLE_EDITS_QUERY: TypedDocumentNode<
  { shoppableEdits: ShoppableEdit[] },
  GetShoppableEditsVariables
> = gql`
  query GetShoppableEditsQuery($client_id: String!) {
    shoppableEdits(client_id: $client_id)
      @rest(
        type: "RestShoppableEdits"
        endpoint: "moda"
        path: "/v3.5/shoppable_edits"
        method: "GET"
      ) {
      id
      name
      items @type(name: "RestShoppableEdits") {
        color
        id
        productId: product_id
        quantity
        size
        skuId: sku_id
        status
        trunkshowId: trunkshow_id
        userId: user_id
        variantId: variant_id
      }
      userId: user_id
      shared
      status
      stylistName: stylist_name
    }
  }
`;
