import React, { useEffect, useRef, useState } from 'react';
import { Clickable } from '@moda/om';
import classNames from 'classnames';
import { MobileNavContent } from '../MobileNavContent';
import { useMobileNavigator } from '../../../hooks/useNavigator';

import './MobileNavMenu.scss';

enum Mode {
  Resting,
  Mounted
}

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  onClose: () => void;
}

export const MobileNavMenu: React.FC<Props> = ({ onClose, ...rest }) => {
  const contentRef = useRef<HTMLDivElement | null>(null);
  const [mode, setMode] = useState<Mode>(Mode.Resting);

  const { data: verticals, loading } = useMobileNavigator();

  useEffect(() => {
    if (!verticals) return;

    setMode(Mode.Mounted);
  }, [verticals]);

  if (loading) {
    return null;
  }

  if (!verticals) {
    return null;
  }

  return (
    <div
      className={classNames('MobileNavMenu', {
        'MobileNavMenu--active': mode === Mode.Mounted
      })}
      {...rest}
    >
      <div className="MobileNavMenu__content-wrapper" ref={contentRef}>
        <MobileNavContent onClose={onClose} />
      </div>

      <Clickable className="MobileNavMenu__close" onClick={onClose} />
    </div>
  );
};
