import React from 'react';
import { Divider, Stack, Text } from '@moda/om';
import { MobileNavigatorUnitListItemFragment } from '../../../generated/types';
import { MobileSiteNavOption } from '../../SiteNav/MobileSiteNavOption';

import './MobileNavSubcategoriesSection.scss';

interface Unit {
  header: string;
  list: MobileNavigatorUnitListItemFragment[];
}

interface Props {
  unit: Unit;
  className?: string;
  onClose: () => void;
}

export const MobileNavSubcategoriesSection: React.FC<Props> = ({ unit, className, onClose }) => (
  <Stack className="MobileNavSubcategoriesSection" space={1}>
    <Divider />
    <Text className="MobileNavSubcategoriesSection__header" treatment="bold1">
      {unit.header}
    </Text>
    <Divider />
    {unit.list.map(({ title, href }, index) => (
      <div key={`${title} - ${index}`}>
        <MobileSiteNavOption
          className={className}
          key={`${title} - ${index}`}
          href={href}
          onClick={onClose}
        >
          {title}
        </MobileSiteNavOption>
      </div>
    ))}
  </Stack>
);
