import React from 'react';
import { Stack } from '@moda/om';
import { useQuery } from '@apollo/client';
import { hrefFor } from '../../routers';
import { Link } from '../Link';
import { useShoppableEditsFlag } from '../../hooks/useShoppableEditsFlag';
import { GET_SHOPPABLE_EDITS_QUERY } from '../ShoppableEdits/shoppableEdits.rest';
import { useUser } from '../../hooks/useUser';
import { UserRewardStatus, UserStatusLevel } from '../../constants';

import './AccountLinks.scss';

export const AccountLinks: React.FC = () => {
  const {
    user: { userLoyaltyStatus, statusLevel }
  } = useUser();

  const showEditsInCustomerAccount = useShoppableEditsFlag();
  const { data: editsData } = useQuery(GET_SHOPPABLE_EDITS_QUERY, {
    skip: !showEditsInCustomerAccount
  });

  const shoppableEdits = Array.isArray(editsData?.shoppableEdits)
    ? editsData.shoppableEdits.filter(edit => edit && edit.id !== null && edit.shared == true)
    : [];

  return (
    <Stack className="AccountLinks" space={4}>
      <Link className="AccountLinks__link" to={hrefFor.AccountSettings()} title="Settings">
        Settings
      </Link>

      {showEditsInCustomerAccount && editsData && shoppableEdits.length > 0 && (
        <Link className="AccountLinks__link" to={hrefFor.EditsPage()}>
          Looks For You
        </Link>
      )}

      <Link className="AccountLinks__link" to={hrefFor.AccountOrderHistory()} title="Order History">
        Order History
      </Link>

      <Link className="AccountLinks__link" to={hrefFor.AccountOpenItems()} title="Open Items">
        Open Items
      </Link>

      <Link className="AccountLinks__link" to={hrefFor.AccountShipments()} title="Shipments">
        Shipments
      </Link>

      <Link className="AccountLinks__link" to={hrefFor.AccountReturns()} title="Returns">
        Returns
      </Link>

      <Link className="AccountLinks__link" to={hrefFor.AccountSiteCredits()} title="Site Credits">
        Site Credits
      </Link>

      {userLoyaltyStatus === UserRewardStatus.Enrolled &&
        statusLevel !== UserStatusLevel.Employee && (
          <Link className="AccountLinks__link" to={hrefFor.AccountRewards()} title="M'O Rewards">
            M'O Rewards
          </Link>
        )}

      <Link className="AccountLinks__link" to={hrefFor.LogoutPage()} title="Sign Out">
        Sign Out
      </Link>

      {userLoyaltyStatus === UserRewardStatus.NotEnrolled &&
        statusLevel !== UserStatusLevel.Employee && (
          <Link
            className="AccountLinks__link"
            to={hrefFor.AccountRewards()}
            title="Join Rewards Program"
          >
            Join Rewards Program
          </Link>
        )}
    </Stack>
  );
};
