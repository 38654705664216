// THIS FILE IS AUTO-GENERATED. DO NOT EDIT.
export const Z_INDEXES = {
  "site-nav": 9000,
  "site-promo-banner-popover": 9050,
  "pdp-email-share-modal": 9051,
  "quick-search-results": 9175,
  "mobile-site-nav-overlay": 9100,
  "modal-overlay": 9900,
  "evidon-banner": 10000,
  "overlay": 500,
  "one": 1,
  "two": 2,
  "favorite-confirm": 3,
  "amazon-widgets-overlay": 100,
  "paypal-button-overlay": 101
};